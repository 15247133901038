.rent {
  .rentPeriod {
    .form-group {
      width: 70px !important;
    }
  }

  .failed {
    color: red;
  }
}

* {
  scroll-behavior: smooth;
}

.scrollable-container {
  overflow-y: auto;
  height: 100px;
  scroll-behavior: smooth;
}

.scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}
